import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GetSidreriasResponse } from '@modules/booking/booking.service';
import { SidreriaComponent } from '../sidreria/sidreria.component';
import { NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'cmp-sidreria-simple',
    templateUrl: './sidreria-simple.component.html',
    styleUrls: ['./sidreria-simple.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        SidreriaComponent,
        NgClass,
    ],
})
export class SidreriaSimpleComponent {
  @Input() data: GetSidreriasResponse[];
  @Input() plan: string;
  @Input() labels;
  @Output() selectedSidreria: EventEmitter<GetSidreriasResponse> = new EventEmitter<GetSidreriasResponse>();
  selectedItem: any = {};

  /**
   * Emit the selected sidreria
   * @param el - Selected sidreria
   */
  emitEvent(el: GetSidreriasResponse) {
    if ((el.is_full !== null && !el.is_full) || this.plan === 'ONLY_BUS_PLAN') {
      this.selectedItem = el;
      this.selectedSidreria.emit(el);
    }
  }
}

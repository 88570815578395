<div class="sidrerias-content">

  <!-- <div class="sidreria-wrapper"
    (click)="emitEvent(item)"
    [style.cursor]="(item?.is_full === null || item?.is_full) && plan === 'SAGARDOBUS_PLAN' ? 'default' : 'pointer'"
    [ngClass]="{ selected: data[i].sidreria.name === selectedItem?.sidreria?.name }">
    <div class="header">
      <p class="sidreria-name non-selectable"><b>{{ item?.sidreria?.name }}</b> <span style="font-size: 0.8rem;"> ({{ item?.sidreria?.population }})</span></p>
    </div>
    <div class="image">
      <img class="image-item" [src]="item?.sidreria?.main_photo" [attr.alt]="item?.sidreria?.name">
    </div>
    <div class="footer" *ngIf="plan === 'ONLY_BUS_PLAN' || (item?.is_full !== null && !item?.is_full)">  
      <div class="price text-center non-selectable">{{ item?.price?.value }} €</div>
    </div>
    <div class="taken" *ngIf="plan === 'SAGARDOBUS_PLAN' && item?.is_full !== null && item?.is_full">
      <span>completa</span>
    </div>
    <div class="pending" *ngIf="plan === 'SAGARDOBUS_PLAN' && item?.is_full === null && item?.bookingsDate">
      <span>Reservas disponibles a partir del {{item?.bookingsDate}}</span>
    </div>
  </div> -->

  <cmp-sidreria
    *ngFor="let item of data; let i = index"
    [ngClass]="{ selected: data[i].sidreria.name === selectedItem?.sidreria?.name }"
    [data]="item.sidreria"
    [labels]="labels"
    [isFull]="item.is_full"
    [bookingsDate]="item?.bookingsDate"
    [plan]="plan"
    [price]="item.price"
    [isBooking]="true"
    (click)="emitEvent(item)"
  ></cmp-sidreria>
</div>
